#mobileNavMenu {
    @media (min-width: 992px) {
        display: none !important;
    }
    .list-group-item-action {
        color: white;
        padding: 0.25rem;
        &:active {
            background-color: $highlight-purple;
        }
        &:hover {
            background-color: $highlight-purple;
        }
        &:focus {
            background-color: $highlight-purple;
        }
    }
}
@media (max-width: 1200px) {
    .mobile-none {
        display: none !important;
    }
}
.list-group-flush > .list-group-item {
    border-width: 0;
    border-bottom: 0;
}
.nav-tabs .nav-link {
    color: $black;
    font-weight: bold;
}

.navbar-light .navbar-nav .nav-link {
    color: $black;
}

.navbar-dark .navbar-nav .nav-link {
    color: white;
}

.nav-tabs {
    .nav-link {
        border-bottom: 2px solid transparent;
        &:hover {
            border-color: transparent;
            border-bottom: 1px solid $highlight-purple;
        }
        &.active {
            color: $purple;
            background-color: #fff;
            border-color: transparent transparent $purple transparent;
        }
    }
}

.page-item.active .page-link {
    background-color: $purple;
    border-color: $purple;
}

.dropdown-item.active,
.dropdown-item:active {
    background-color: $highlight-purple;
}

.glow {
    color: #fff;
    text-align: center;
    -webkit-animation: glow 1s ease-in-out infinite alternate;
    -moz-animation: glow 1s ease-in-out infinite alternate;
    animation: glow 1s ease-in-out infinite alternate;
}

@-webkit-keyframes glow {
    from {
        text-shadow: 0 0 10px #fff, 0 0 20px #fff, 0 0 30px $purple,
            0 0 40px $purple, 0 0 50px $purple, 0 0 60px $purple,
            0 0 70px #e60073;
    }
    to {
        text-shadow: 0 0 20px #fff, 0 0 30px $highlight-purple,
            0 0 40px $highlight-purple, 0 0 50px $highlight-purple,
            0 0 60px $highlight-purple, 0 0 70px $highlight-purple,
            0 0 80px $highlight-purple;
    }
}

.side-menu-wrapper {
    position: fixed;
    top: 0;
    left: -325px;
    z-index: 1021;
    width: 325px;
    height: 100%;
    overflow-x: hidden;
    color: white;
    background-color: $black;
    transition: 0.5s;
    .side-menu {
        font-size: 18px;
        color: white;
        height: 85vh;
        padding: 0 1.5rem;
        .nav-link {
            padding: 1rem 0;
            a {
                color: white;
            }
        }
    }
}
