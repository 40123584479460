.badge-purple {
  color: white;
  background-color: $purple;
}

.badge-outline-purple {
  color: white;
  border: $purple 2px solid;
  background-color: transparent;

}

.badge-grey {
  color: #AAAAAA;
  background-color: #E7E7E7;
}

.badge-black {
  color: white;
  background-color: black;
}


.icon-badge-wrapper {
  position: relative;

  .badge-top-right {
    position: absolute;
    top: -10px;
    right: -7px;
    padding: 0.25rem 0.4rem;
  }
}
.badge-padded {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;

}
