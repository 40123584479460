/* vSelect Styles */
$vs-border-radius: $form-border-radius;
$vs-border-color: $light-grey;
$vs-component-placeholder-color: $grey;
$vs-state-active-bg: $light-grey;
$vs-selected-bg: $light-grey;
$vs-state-disabled-bg: $light-grey;

textarea {
  border-radius: 1rem !important;
}

.input-group-sm {
  .form-control {
    border-radius: inherit;
  }
  .input-group-prepend > .input-group-text {
    border-radius: $form-border-radius;
  }
  .input-group-append > .input-group-text {
    border-radius: $form-border-radius;
  }
}

.form-control {
  @extend .form-control;
  background-color: $light-grey;
  border-color: $light-grey;
  border-radius: $form-border-radius;
  &.StripElement {
    padding-top: 2px;
  }
  &:focus {
    background-color: $light-grey;
  }
  &:disabled {
    color: #495057;
    background-color: #e9ecef !important;
    border-color: #e9ecef;
    opacity: 1;
  }
  &.b-calendar-grid {
    border-radius: 0.25rem;
  }
}

.form-control-file {
  .custom-file-label {
    background-color: $light-grey;
    border-radius: $form-border-radius;
  }
}

.form-control-black {
  color: white;
  background-color: $black;
  border-radius: $form-border-radius;
  &:focus {
    border-color: $purple;
    color: white;
    background-color: $black;
  }
}

.form-control-white {
  color: $black;
  background-color: #FFF;
  border-radius: 6px;
  border-color: #cbc7c7;
  &:focus {
    border-color: $purple;
    color: $black;
    background-color: #FFF;
  }
  &::-webkit-input-placeholder { ///* Edge */
    color:#cbc7c7;
  }
  &:-ms-input-placeholder { ///* Internet Explorer */
    color: #cbc7c7;
  }
  &::placeholder {
    color: #cbc7c7;
  }
}

.input-group {
  .input-group-prepend {
    margin-right: -2px;
  }
  .input-group-text {
    border-radius: $form-border-radius;
    background-color: $light-grey;
    border-color: $light-grey;
  }
  &.disabled {
    .input-group-text {
      color: #495057;
      background-color: #e9ecef !important;
      border-color: #e9ecef;
      opacity: 1;
    }
  }
}

.input-group-dark {
  background-color: $black;
  border: $purple 2px solid;
  border-radius: 50rem;
  .input-group-text {
    color: white;
    background-color: $black;
    border-color: $black;
  }
  .form-control-black {
    border: 0;
  }
}

.custom-select {
  background-color: $light-grey !important;
  border-color: $light-grey;
  border-radius: $form-border-radius;
  &:disabled {
    color: #495057;
    background-color: #e9ecef !important;
    border-color: #e9ecef;
    opacity: 1;
  }
}

.custom-select-white {
  background-color: $white !important;
  border-color: #cbc7c7;
  border-radius: 6px;
  &:disabled {
    color: #495057;
    background-color: #e9ecef !important;
    border-color: #e9ecef;
    opacity: 1;
  }
}

.vs__dropdown-toggle {
  border: 0;
}

.v-select {
  background: #E9E9E9;
  border-radius: $form-border-radius;
  padding: 0.25rem 0.75rem;
}
.v-select.vs--open {
  border-radius: 15px 15px 0 0;
}
.v-select.v-select-error {
  border: red solid 1px;
}

.reaction {
  padding: 0.5rem;
  text-align: center;
  position: relative;
  span {
    position: absolute;
    opacity: 0;
    top: -35%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 0.25rem 0.5rem;
    font-size: small;
    border-radius: 15rem;
    background-color: rgba(0, 0, 0, 0.75);
    transition: opacity 250ms;
    transition-timing-function: ease-in-out;
  }
  i {
    font-size: 1.5rem;
    transition: font-size 250ms;
    transition-timing-function: ease-in-out;
  }
  &:hover {
    span {
      opacity: 100;
    }
    i {
      font-size: 1.75rem;
    }
  }
}

// Override existing tag styles
.content-user-tag[style] {
    background-color: rgba(24, 119, 242, 0.2) !important;
}