.btn-padded {
  padding: 0.375rem 2rem;
}

.btn-link {
  color: $purple;
  &:hover {
    color: $highlight-purple;
  }
}

.btn-purple {
  background-color: $purple;
  color: white;
  transition: all .15s;
  &:hover {
    color: white;
    background-color: $highlight-purple;
    transform: scale(1.05);
    box-shadow: 0 4px 5px rgba(0, 0, 0, 0.25);
  }
}

.btn-outline-purple {
  background-color: transparent;
  color: white;
  border: 2px solid $purple;
  &:hover {
    background-color: $purple;
    color: white;
  }
}

.btn-outline-purple-purple {
  background-color: transparent;
  color: $purple;
  border: 2px solid $purple;
  &:hover {
    background-color: $purple;
    color: white;
  }
  &.active {
    background-color: $purple;
    color: white;
  }
}

.btn-ghost-purple {
  background-color: transparent;
  border: none;
  color: $grey;
  &:hover {
    color: $highlight-purple;
  }
  &.active {
    color: $purple
  }
}

.btn-group-pill {
  :first-child {
    border-top-left-radius: 50rem;
    border-bottom-left-radius: 50rem;
  }
  :last-child {
    border-top-right-radius: 50rem;
    border-bottom-right-radius: 50rem;
  }
}

.btn-red {
  font-weight: lighter;
  background-color: $red;
  color: white;
  transition: all .15s;
  border-color: transparent;
  box-shadow: 0px 4px 15px -7px #000000;
  padding: 8px 36px;
  font-size: 14px;
  line-height: 21px;
  &:hover {
    color: white;
    background-color: $highlight-red;
    transform: scale(1.05);
    box-shadow: 0 4px 5px rgba(0, 0, 0, 0.25);
    
  }
}

.bg-red-fancy {
  background:  radial-gradient(farthest-corner at 200% 200%, #dd6480 70% , $red 10%);
  transition: all .15s;
  &:hover {
    box-shadow: 0 4px 5px rgba(0, 0, 0, 0.25);
  }
}

.bg-purple-fancy {
  background:  radial-gradient(farthest-corner at 200% 200%, #6c6ada 70% , $purple 10%);
  transition: all .15s;
  &:hover {
    box-shadow: 0 4px 5px rgba(0, 0, 0, 0.25);
  }
}

.btn-black {
  font-weight: lighter;
  background-color: $black;
  color: white;
  transition: all .15s;
  border-color: transparent;
  box-shadow: 0 6px 6px -6px black;
  &:hover {
    color: white;
    background-color: $dark-grey;
    transform: scale(1.05);
    box-shadow: 0 4px 5px rgba(0, 0, 0, 0.25);
  }
}