.b-avatar-img {
  img {
    object-fit: cover;
  }
}

.welcome-hero {
  display: inline-block;
  position: relative;

  &::before {
    position: absolute;
    top: -7%;
    left: -7%;
    width: 100%;
    height: 100%;
    border: 1px solid $purple;
    content: '';
  }
}

.hero-video-logo {
  object-fit: cover;
  width: 300px;
  height: 300px;
  @media(min-width: 768px) {
    width: 450px;
    height: 450px;
  }
}

.league-profile-header {
  .banner-container {
    position: relative;
    &:hover .banner-overlay {
      opacity: 100;
      background-color: rgba(0,0,0,0.5);
    }
    .banner-image {
      display:block;
      width: 100%;
      background-position: center;
      background-size: cover;
      transition: .5s ease;
      backface-visibility: hidden;
    }
    .banner-overlay {
      transition: .5s ease;
      position: absolute;
      opacity: 0;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      text-align: center;
      background-color: rgba(0,0,0,0);
    }
  }
  .header-content {
    .media-body {
      z-index: 1;
    }
    .profile-container {
      position: relative;
      &:hover .profile-overlay {
        opacity: 100;
        background-color: rgba(0,0,0,0.5);
      }
      .profile-avatar {
        transition: .5s ease;
        backface-visibility: hidden;
        border: 1px white solid;
      }
      .profile-overlay {
        z-index: 1;
        transition: .5s ease;
        position: absolute;
        opacity: 0;
        top: 0;
        left: 0;
        border-radius: 50%;
        width: 100%;
        height: 100%;
        text-align: center;
        background-color: rgba(0,0,0,0);
      }
    }
  }
}

.user-profile-header {
  .banner-container {
    position: relative;
    &:hover .banner-overlay {
      opacity: 100;
      background-color: rgba(0,0,0,0.5);
    }
    .banner-image {
      display:block;
      width: 100%;
      background-position: center;
      background-size: cover;
      transition: .5s ease;
      backface-visibility: hidden;
    }
    .banner-overlay {
      transition: .5s ease;
      position: absolute;
      opacity: 0;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      text-align: center;
      background-color: rgba(0,0,0,0);
    }
  }
  .header-content {
    .media-body {
      z-index: 1;
    }
    .profile-container {
      position: relative;
      &:hover .profile-overlay {
        opacity: 100;
        background-color: rgba(0,0,0,0.5);
      }
      .profile-avatar {
        transition: .5s ease;
        backface-visibility: hidden;
        border: 1px white solid;
      }
      .profile-overlay {
        z-index: 1;
        transition: .5s ease;
        position: absolute;
        opacity: 0;
        top: 0;
        left: 0;
        border-radius: 50%;
        width: 100%;
        height: 100%;
        text-align: center;
        background-color: rgba(0,0,0,0);
      }
    }
  }
}
// .store-add-digital-overlay{
//   background-color: $light-grey;
//   border-radius: 20px;
//   &:hover {
//     opacity: 100;
//     background-color: rgba(0,0,0,0.5);
//     border-radius: 20px;
//   }
// }