.media-item-comments-modal {
    .modal-dialog {
        position: relative;
        width: auto;
        margin: 0px;
        pointer-events: none;
        .modal-header {
            background-color: #0e0e0e;
            border-top-left-radius: 0px;
            border-top-right-radius: 0px;
            .fas.fa-arrow-left {
                color: white;
            }
        }
        .modal-content {
            min-height: 100vh;
            border-radius: 0px;
            border: 0px;
            .modal-body {
                width: 100%;
                margin: auto;
                padding: 0px;
                .modal-body-top {
                    padding-top: 0.5rem;
                    .modal-body-top-header {
                        padding-left: 0.5rem;
                        padding-right: 0.5rem;
                    }
                }
                .modal-body-bottom {
                    padding-left: 0.5rem;
                    padding-right: 0.5rem;
                }

                @media (min-width: 992px) {
                    width: 700px;
                }
            }
        }

        @media (min-width: 576px) {
            max-width: none;
        }
    }
}
// Share Post
div#share-post div.vs__dropdown-toggle div.vs__selected-options span.vs__selected {
    display: block;
    align-items: center;
    background-color: $green;
    border: 1px solid rgb(46 161 72);
    border-radius: 10px;
    color: #fff;
    line-height: 1.4;
    margin: 4px 4px 0px 2px;
    padding: 0 0.9em;
    z-index: 0;
}
div#share-post div.vs__dropdown-toggle div.vs__selected-options span.vs__selected button.vs__deselect {
    fill: black;
}

// For fullscreen modals use size="full"
// @media (min-width: 992px) {
.modal .modal-full {
    max-width: 100% !important;
    width: 100% !important;
    margin: 0px !important;
    padding: 0px !important;
}
// }

// For centered modals use size="xl-center"
.modal .modal-xl-center {
    max-width: 1140px;
    .modal-content {
        @media (min-width: 576px) {
            min-height: auto;
            background-color: transparent;
            flex-direction: row !important; // Media tab
        }
    }
}
div#media-item-comments-modal {
    .modal-content {
        background-color: white!important; //Wall media modal
    }
}
div#membership-plan {
    .modal-content {
        background-color: white!important; //Membership Plan modal
        border-radius: 9px;
    }
}
div#store-product-details-modal {
    .modal-content {
        background-color: white!important; //Store Product Item
        border-radius: 20px;
    }
}

ul[role=tablist], #membership-tabs__BV_tab_controls_, .nav-tabs {
    border:none!important;
}

.create-burst-modal {
    .modal-header {
        background-color: #000;
        border-bottom: 0px;
        display: block;
    }
    .modal-body {
        background-color: #000;
    }
}
.edit-media-image {
    .modal-header {
        background-color: #000;
        border-bottom: 0px;
        display: block;
    }
    .modal-body {
        background-color: #000;
    }
}
