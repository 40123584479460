.media-card {
  .delete-button {
    display: none;
    position: absolute;
    top: -7px;
    right: -7px;
    z-index: 1;
  }
  &:hover {
    .delete-button {
      display: block;
    }
  }
  .edit-button {
    display: none;
    position: absolute;
    top: -7px;
    right: 25px;
    z-index: 1;
  }
  &:hover {
    .edit-button {
      display: block;
    }
  }
}
