.burst-replies-video-wrapper {
  .video-js {
    width: 100%;
    height: 100%;
    &.vjs-portrait-mode {
      height: 500px !important;
      @media (min-width: 992px) {
        height: 600px !important;
      }
      .vjs-tech {
        object-fit: cover !important;
      }
      .vjs-poster {
        position: inherit !important;
        object-fit: cover !important;
      }
    }
    &.vjs-square-mode {
      height: 380px;
      @media (min-width: 992px) {
        height: 600px !important;
      }
      .vjs-tech {
        object-fit: cover !important;
      }
      .vjs-poster {
        position: inherit !important;
        object-fit: cover !important;
      }
    }
    .vjs-big-play-button {
      width: 100px;
      height: 100px;
      border-radius: 50%;
      .vjs-icon-placeholder:before {
        position: absolute;
        top: 24px;
        left: 0;
        width: 100%;
        height: 100%;
        font-size: 48px;
      }
    }
  }
}
