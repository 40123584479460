.bg-black {
    background-color: $black;
}

.bg-purple {
    background-color: $purple;
}

.bg-media-black {
    background: radial-gradient($dark-grey, $black);
}

.bg-darkgrey {
    background-color: $dark-grey;
}

.bg-lightgrey {
    background-color: $light-grey;
}

.bg-grey {
    background-color: $grey;
}

/* This is used on top of images to create the
 * gradiant overlay that is used under text
 */
.bg-gradiant-overlay {
    background: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0) 0%,
        rgba(35, 35, 35, 0.72) 100%
    );
}

.bg-public {
    background-color: white;
    background-image: url("/images/backgrounds/mesh-mountains.jpg");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: bottom center;
}
