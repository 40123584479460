main {
  position: relative;
}

.content {
  position: relative;
  min-height: calc(100vh - 72px);
  > .container:not(.page-container) {
    margin-top: 5rem;
    margin-bottom: 5rem;
    #error-container {
      top: -4rem;
    }
  }
  > .notification-container {
    margin-top: 2rem;
    margin-bottom: 5rem;
    #error-container {
      top: -4rem;
    }
  }
  > .page-container {
    #error-container {
      top: 1rem;
    }
  }
}

.public-content {
  position: relative;
  padding: 3rem 1rem;
  min-height: 80vh;
}

.public-description {
    padding-bottom: 1rem;
    font-style: italic;
    text-align: center;
}

@media (max-width: 414px) {
    .public-content {
        padding: .5rem 1rem;
    }
    .public-description {
        padding-bottom: 0rem;
    }
}

.page-container {
  @extend .container;
  min-height: calc(100vh - 72px);
  background-color: white;
  padding-top: 5rem;
  padding-bottom: 5rem;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15);
}

footer {
  &.private-footer {
    padding: 2rem;
    background-color: $black;
  }
}

.wh-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.abs-top-right {
  position: absolute;
  top: 10px;
  right: 10px;
}

.active-border {
  border: 3px $highlight-purple solid;
}

.clickable {
  cursor: pointer;
}

.drop-shadow {
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15);
}

.top-0 {
  top: 0;
}
.right-0 {
  right: 0;
}
.bottom-0 {
  bottom: 0;
}
.left-0 {
  left: 0;
}
.rounded-xl {
  border-radius: 0.8rem;
}
.ellipsis {
  padding: 0 0.25rem 0 0.25rem;
  margin-left: 0.25rem;
  .text-grey {
    color: $grey;
  }
}
.ellipsis:hover {
  background-color: $light-grey;
  border-radius: 100%;
}
html {
    scroll-behavior: smooth;
}
@media screen and (prefers-reduced-motion: reduce) {
    html {
        scroll-behavior: auto;
   }
}

@media (min-width: 1200px) {
  .container-xl,
  .container-lg,
  .container-md,
  .container-sm,
  .container {
    max-width: 960px;
  }
}

/** Croppie **/
div .cr-boundary {
  width: 100% !important;
}
