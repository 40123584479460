// Fonts
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@300;400;700&display=swap');

// Sizes
.headline {
  font-size: 2.5rem;
  font-weight: 900;
  margin-bottom: 3rem;
  @media(min-width: 768px) {
    font-size: 4.5rem;
  }
}

// Utilities
.truncate {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

// Colors
.text-purple {
  color: $purple !important;
}

.text-light-purple {
  color: $highlight-purple !important;
}

.text-gold {
  color: $gold;
}

.text-grey {
  color: $grey;
}

a {
  color: $purple;
  &:hover {
    color: $highlight-purple;
  }
}

// Specific Styles
.hero-title {
  color: $black;
  margin-top: 2rem;
  margin-bottom: 2rem;
  font-weight: 900 !important;
  line-height: 1.2;
  font-size: 3rem;
  @media(min-width: 768px) {
    font-size: 3rem;
  }
}

.hero-text {
  font-family: "Roboto Condensed", sans-serif;
  font-size: 14px;
  line-height: 175%;
}

.hero-check-text {
  font-family: "Roboto Condensed", sans-serif;
  font-weight: bold;
  letter-spacing: 0.4625px;
}

.text-2xs {font-size:	0.625rem;}  // 10px
.text-xs	{font-size: 0.775rem;}  // 12px
.text-sm	{font-size: 0.875rem;}  // 14px
.text-base	{font-size: 1rem;}    // 16px
.text-lg	{font-size: 1.125rem;}  // 18px
.text-xl	{font-size: 1.25rem;}   // 20px
.text-2xl	{font-size: 1.5rem;}    // 24px

.leading-4 {line-height: 1rem;}   // 16px
.leading-5 {line-height: 1.25rem;}// 18px
.leading-6 {line-height: 1.5rem;} // 24px

.text-decoration-line-through{ text-decoration: line-through }
.text-decoration-underline{text-decoration: underline}

.cursor-pointer { cursor: pointer }
.cursor-not-allowed	{ cursor: not-allowed}

.font-weight-bolded{font-weight: bolder}